import React, { useState } from 'react';
import { Card } from '@urbansportsclub/components/Card';
import { Text } from '@urbansportsclub/components/Text';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import * as styles from './PersonnelCard.variants';
import { Radio } from '@urbansportsclub/components/Radio';
import { Personnel } from 'models/employee';

interface IPersonnelCard {
  data?: Personnel
  selectedId: string | null,
  setSelectedId: (id: string) => void,
}

const PersonnelCard = ({ data, selectedId, setSelectedId } : IPersonnelCard) => {
  const isSelected = data?.hri_id == selectedId;

  if (!data) return <></>;

  return (
    <Card variant='option' className={ styles.cardVariant({ isSelected }) } onClick={() => setSelectedId(data.hri_id)}>
      <div className='flex flex-col overflow-hidden'>
        <Text as='span' intent='md' weight='bold' className='max-sm:text-sm'>{`${data.first_name} ${data.last_name}`}</Text>
        <Text as='span' intent='md' className='max-sm:text-sm text-grey-dark-700 overflow-hidden text-ellipsis'>{data.email}</Text>
        <Text as='span' intent='md' className='max-sm:text-sm text-grey-dark-700'>Personnel number: {data.employee_number}</Text>
        <Text as='span' intent='md' className='max-sm:text-sm text-grey-dark-700'>Start date: {data.start_date}</Text>
      </div>
      <Radio checked={isSelected} className={styles.radio()} />
    </Card>
  );
};

export default PersonnelCard;
