import { CompanyContext } from 'contexts/CompanyContext';
import { useContext, useState } from 'react';
import API from 'utils/api';

export const useMatchEmployees = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [data, setData] = useState<any>();

  const getMatchingEmployees = async (companyId: string, employeeId: string, email?: string) => {
    setLoading(true);
    return API.post(`/companies/${companyId}/employee/${employeeId}/search_hri`, { email: email })
      .then((response: any) => {
        setLoading(false);
        setData(response?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.email?.message) {
          setErrorMsg(err.response.data.email.message);
        }
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    errorMsg,
    setErrorMsg,
    getMatchingEmployees,
    data,
    setData,
  };
};

export const useConnectEmployee = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const getConnectEmployee = async (companyId: string, employeeId: string, hri_id: string) => {
    setLoading(true);
    return API.post(`/companies/${companyId}/employee/${employeeId}/connect`, { hri_id: hri_id })
      .then((response: any) => {
        setLoading(false);
        setData(response?.data);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return {
    loading,
    hasError,
    getConnectEmployee,
    data,
  };
};
