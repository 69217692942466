import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import {
  GET_EMPLOYEES,
  EmployeesData,
} from 'utils/graphql/queries/employee';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import { CompanyContext } from 'contexts/CompanyContext';
import EmployeesList from './components/EmployeesList';
import { Text } from '@urbansportsclub/components/Text';
import HrData from './components/HrData';
import { useConnectEmployee, useMatchEmployees } from 'services/syncEmployeeService';
import { Button } from '@urbansportsclub/components/Button';
import useDebounce from 'utils/hooks/useDebounce';
import { isEmailValid } from 'utils/helpers';
import EmployeesListDialog from './components/EmployeeListDialog';
import LinkConfirmationDialog from './components/LinkConfirmationDialog';
import { Personnel } from 'models/employee';
import { useTranslation } from 'react-i18next';
import { EmployeeSelectedFromSearchList, HrManualButtonClicked, SearchEvent } from './ManualDataTracking';

const MatchDataManually = () => {
  const { company } = useContext(CompanyContext);
  const { getMatchingEmployees, data: matchedEmployees, setData: setMatchedEmployees, loading: loadingMatches } = useMatchEmployees();
  const { getConnectEmployee } = useConnectEmployee();
  const { t } = useTranslation();
  
  const {
    data,
    loading,
    refetch,
    error,
  } = useQuery<EmployeesData>(
    GET_EMPLOYEES,
    {
      variables: {
        itemsPerPage: 999,
        companyId: company ? company.id : null,
        hriEmployeeId: 'null',
        order: [{ firstName: 'asc' }, { lastName: 'asc' }],
      },
    },
  );

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(null);
  const [selectedPersonnelId, setSelectedPersonnelId] = useState<string | null>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const debouncedSearchInput = useDebounce<string>(searchInputValue, 500);

  useEffect(() => {
    if (company?.id && !selectedEmployeeId && data?.employees?.collection?.length) {
      setSelectedEmployeeId(data.employees.collection[0].id);
    }
  }, [company?.id, selectedEmployeeId, data?.employees?.collection?.length]);

  useEffect(() => {
    if (company?.id && selectedEmployeeId) {
      getMatchingEmployees(company.id, selectedEmployeeId.replace('/employees/', ''));
    }
  }, [company?.id, selectedEmployeeId]);

  useEffect(() => {
    setIsValidEmail(true);

    if (selectedEmployeeId && isEmailValid(debouncedSearchInput)) {
      SearchEvent();
      getMatchingEmployees(company?.id as string, selectedEmployeeId.replace('/employees/', ''), debouncedSearchInput);
    }

    if (selectedEmployeeId && !isEmailValid(debouncedSearchInput)) {
      if (debouncedSearchInput === '') {
        getMatchingEmployees(company?.id as string, selectedEmployeeId.replace('/employees/', ''));
      } else {
        setIsValidEmail(false);
      }
    }
  }, [debouncedSearchInput]);

  const linkEmployee = async () => {
    if (company?.id && selectedEmployeeId && selectedPersonnelId) {
      HrManualButtonClicked('Sync Data');
      await getConnectEmployee(company?.id, selectedEmployeeId.replace('/employees/', ''), selectedPersonnelId);
      toast('Employee is successfully synced');
      handleSkipemployee();
      await refetch();
    }
    setConfirmationOpen(false);
  };

  const handleLinkEmployee = () => {
    setConfirmationOpen(true);
    setSearchInputValue('');
  };

  const handleSkipemployee = () => {
    const selectedEmployeeIndex = data?.employees.collection.findIndex(employee => employee.id === selectedEmployeeId);
    if (selectedEmployeeIndex !== undefined && selectedEmployeeIndex !== -1) {
      const newIndex = selectedEmployeeIndex === data?.employees?.collection?.length as number - 1 ? 0 : selectedEmployeeIndex + 1;
      setSelectedEmployeeId(data?.employees.collection[newIndex].id as string);
      setSelectedPersonnelId(null);
      setMatchedEmployees(null);
      HrManualButtonClicked('Skip Employee');
    }
  };

  const handleSearchPersonnel = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(value);
  };

  const handleClearSearch = () => {
    setSearchInputValue('');
  };

  return (
    <SidebarLayout heading={t('hri_sync.page_title')} showDownloadList={false} className='relative'>
      <div className='flex flex-col md:flex-row md:gap-x-20'>
        <div className='md:flex-[2]'>
          <Text as='h2' intent='lg' weight='bold' className='mb-4 hidden md:block'>{t('hri_sync.employees_title')}</Text>
          <div className='flex justify-between items-center md:hidden'>
            <Text as='h3' intent='md' weight='bold' className='capitalize'>{data?.employees?.collection?.length} {t('hri_sync.unsynced.mob')}</Text>
            <Button variant='link' className='bg-transparent capitalize' onClick={() => setDialogOpen(true)}>{t('hri_sync.mob_drawer.subtitle')}</Button>
          </div>
          <EmployeesList
            data={data}
            selectedEmployeeId={selectedEmployeeId}
            setSelectedEmployeeId={(id) => {
              setSelectedEmployeeId(id);
              setSelectedPersonnelId(null);
              setMatchedEmployees(null);
              HrManualButtonClicked('Employee Selected');
            }}
          />
        </div>
        <div className='md:flex-[3] max-md:mb-12'>
          <Text as='h2' intent='lg' weight='bold' className='mb-4 hidden md:block capitalize'>{company?.hrIntegrationName} {t('hri_sync.hri_title')}</Text>
          <HrData
            isLoading={loadingMatches}
            data={matchedEmployees ? matchedEmployees.match_employees : []}
            selectedPersonnelId={selectedPersonnelId}
            setSelectedPersonnelId={(id:string) => {
              setSelectedPersonnelId(id);
              EmployeeSelectedFromSearchList();
            }}
            searchInputValue={searchInputValue}
            onSearchHandler={handleSearchPersonnel}
            onSearchClear={handleClearSearch}
            errorMsg={!isValidEmail ? 'Please enter a valid email address' : ''}
            debouncedSearchInput={debouncedSearchInput}
          />
        </div>
      </div>
      <div className='border-t border-grey-dark-200 fixed bottom-0 w-full lg:w-[calc(100%-288px)] bg-white-natural-500 ml-[-16px] sm:ml-[-24px] lg:ml-[-32px] px-6 py-4 md:px-10 md:py-6 flex flex-row items-center justify-between'>
        <Text as='h3' intent='md' weight='bold' className='hidden md:block'>{data?.employees?.collection?.length} {t('hri_sync.unsynced.web')}</Text>
        <div className='flex flex-row gap-x-4 w-full md:w-[unset]'>
          <Button className='w-full md:w-[unset]' disabled={!selectedEmployeeId} variant="link" onClick={handleSkipemployee}>{t('hri_sync.actions.skip_employee')}</Button>
          <Button className='w-full md:w-[unset]' disabled={!selectedEmployeeId || !selectedPersonnelId} onClick={handleLinkEmployee}>{t('hri_sync.actions.link_employee')}</Button>
        </div>
      </div>
      <EmployeesListDialog
        data={data}
        selectedEmployeeId={selectedEmployeeId}
        setSelectedEmployeeId={(id) => {
          setSelectedEmployeeId(id);
          setSelectedPersonnelId(null);
          setMatchedEmployees(null);
          HrManualButtonClicked('Employee Selected');
        }}
        isOpen={isDialogOpen}
        setOpen={setDialogOpen}
      />
      <LinkConfirmationDialog
        isOpen={isConfirmationOpen}
        onConfirm={linkEmployee}
        onCancel={() => {
          HrManualButtonClicked('Cancel Match');
          setConfirmationOpen(false);
        }}
        uscEmployee={data?.employees.collection.find(employee => employee.id === selectedEmployeeId)}
        hriEmployee={matchedEmployees?.match_employees.find((employee: Personnel) => employee.hri_id === selectedPersonnelId)}
      />
    </SidebarLayout>
  );
};

export default MatchDataManually;
