import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import 'react-dropdown/style.css';
import { Props as EmployeesListHeaderProps } from 'components/molecules/EmployeesListHeader';

import { EmployeesData } from 'utils/graphql/queries/employee';
import { Employee, EmployeeState, EmployeeStateActions } from 'models/employee';
import { formatDate } from 'utils/date';
import { EMPLOYEES_SORT_BY_VALUES, PAGINATION_ITEMS_PER_LIST_VALUES } from 'utils/constants';

import './EmployeesList.scss';
import Table from '../Table';
import { ColumnTypes, TableColumn, Variants } from '../Table/Table';
import Button, { ButtonSize, ButtonVariants } from 'components/atoms/Tailwind/Button';
import { Button as USCButton } from '@urbansportsclub/components/Button';
import { ButtonLayerEvent, pushToButtonDataLayer } from 'utils/dataLayer';
import { useEffect, useState } from 'react';
import Popup from '../Popup';
import { ITab } from 'models/generic';
import { ArrowPathIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
import { capitalizeFirstLetter } from 'utils/helpers';
import { Company } from 'models/company';
import InviteEmployee from '../InviteEmployee/InviteEmployee';
import StatusCard from './components/StatusCard';

export interface Props extends EmployeesListHeaderProps {
  onPrimaryButtonClick?: (id: string) => unknown
  primaryButtonText?: string;
  onSecondaryButtonClick?: (id: string) => unknown;
  secondaryButtonText?: string;
  emptyListText: string;
  data: EmployeesData | undefined;
  onPaginationChange: (page: number, itemsPerPage?: number) => void;
  forcePage: number | undefined;
  activePage: number;
  error?: ApolloError;
  activeTab: ITab;
  isLoading?: boolean;
  onEmployeeBulkApprove?: (ids: string[]) => void;
  onEmployeeBulkCancel?: (ids: string[]) => void;
  allowBulkCancel?: boolean;
  company?: Company;
  sortedColumn: any;
}

const onBulkButtonClickedEvent: ButtonLayerEvent = {
  item: 'Allow Bulk Approve',
  details: 'Bulk Approve Selection',
  origin: 'EmployeesList',
};

const onBulkApprovedButtonClickedEvent: ButtonLayerEvent = {
  item: 'Bulk Approve',
  details: 'Bulk Approve Selected',
  origin: 'EmployeesList',
};

const statesForBulkProcessWithoutApproval = [EmployeeState.PENDING_APPROVAL];
const statesForBulkProcessWithApproval = [EmployeeState.ACTIVE, EmployeeState.PENDING_APPROVAL];

const EmployeesList = ({
  onPrimaryButtonClick,
  primaryButtonText,
  onSecondaryButtonClick,
  secondaryButtonText,
  emptyListText,
  data,
  onPaginationChange,
  activePage,
  error,
  onSortChange,
  activeTab,
  onEmployeeBulkApprove,
  onEmployeeBulkCancel,
  isLoading,
  allowBulkCancel,
  company,
  sortedColumn,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { collection, paginationInfo } = data?.employees || {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCancelledEmployeeIds, setSelectedCancelledEmployeeIds] = useState<string[]>([]);
  const statesForBulkProcess = allowBulkCancel ? statesForBulkProcessWithApproval : statesForBulkProcessWithoutApproval;
  const [selectedRows, setSelectedRows] = useState([] as string[]);

  useEffect(() => {
    setSelectedRows([]);
  }, [activeTab.generalState]);

  if (error?.message?.length) {
    return <div className="employees-list">{t('employees_page.list.error_message')}</div>;
  }

  if (!collection?.length) {
    return activeTab.generalState === EmployeeState.ACTIVE ? (
      <InviteEmployee className='mt-8'/>
    ) : (
      <div className="employees-list">{emptyListText}</div>
    );
  }

  function onBulkApprove(selectedIds: string[]) {
    pushToButtonDataLayer(onBulkApprovedButtonClickedEvent);
    onEmployeeBulkApprove && onEmployeeBulkApprove(selectedIds);
    setSelectedRows([]);
  }

  function onBulkCancel(selectedIds: string[]) {
    setSelectedCancelledEmployeeIds(selectedIds);
    setIsDialogOpen(true);
  }

  function onBulkCancelConfirm() {
    onEmployeeBulkCancel && onEmployeeBulkCancel(selectedCancelledEmployeeIds);
    setSelectedRows([]);
    setIsDialogOpen(false);
  }

  const columns: TableColumn[] = [
    { name: 'Name', key: 'name', className: activeTab.generalState === EmployeeState.ACTIVE ? 'text-left md:w-[40%] lg:w-[unset]' : 'text-left max-w-[145px] w-[55%] md:w-[40%]', render: (row:object) => {
      const employee = row as Employee;
      return (
        <div className='ml-4 flex flex-col'>
          <span className='text-base overflow-hidden whitespace-nowrap text-ellipsis'>{`${employee.firstName} ${employee.lastName}`}</span>
          <span className='sub text-sm overflow-hidden whitespace-nowrap text-ellipsis hidden md:inline'>{employee.email}</span>
          { activeTab.generalState === EmployeeState.PENDING_APPROVAL ?
            (
              <span className='sub text-sm overflow-hidden whitespace-nowrap text-ellipsis md:hidden'>
                {employee.membershipPlanTypeName}
              </span>
            )
            : (
              <span className='sub text-sm overflow-hidden whitespace-nowrap text-ellipsis md:hidden'>
                {employee.membershipPlanTypeName}<span className='inline-block mx-1 w-[4px] h-[4px] rounded-full relative bottom-0.5 bg-grey-dark-500'/>{t(`employees_page.details.membership_state_badges.${employee.membershipState}`)}
              </span>
            )
          }
        </div>
      );
    } },
    { name: 'City', key: 'membership_integrationMembership_cityName', className: 'max-lg:hidden text-left', render: (row) => {
      const employee = row as Employee;
      return (
        <div className='mx-4 flex'>
          <span className='sub text-sm overflow-hidden line-clamp-2'>{employee.membershipCityName}</span>
        </div>
      );
    } },
    { name: 'Start Date', key: 'membership_startDate', className: activeTab.generalState === EmployeeState.ACTIVE ? 'max-lg:hidden text-left justify-start w-[15%]' : 'max-lg:hidden text-left justify-start', render: (row) => {
      const employee = row as Employee;
      return (
        <span className='sub text-sm mx-4'>{formatDate(new Date(employee.membershipStartDate))}</span>
      );
    } },
    { name: 'Plan', key: 'membership_integrationMembership_planTypeName', className: 'max-lg:hidden text-left justify-starts w-[8%]', render: (row) => {
      const employee = row as Employee;
      return (
        <span className='sub text-sm mx-4'>{employee.membershipPlanTypeName}</span>
      );
    } },
    { name: 'Status', key: 'membership_state', className: 'max-md:hidden justify-start lg:w-[10%]', render: (row) => {
      const employee = row as Employee;

      switch (employee.membershipState) {
        case EmployeeState.ACTIVE:
          return <StatusCard id={employee.email} title={t(`employees_page.details.membership_state_badges.${employee.membershipState}`)} tooltipText={t(`employees_page.details.membership_state_tooltips.${employee.membershipState}`)} color='bg-[#2EBA88]' />;
        case EmployeeState.PAUSED:
          return <StatusCard id={employee.email} title={t(`employees_page.details.membership_state_badges.${employee.membershipState}`)} tooltipText={t(`employees_page.details.membership_state_tooltips.${employee.membershipState}`)} color='bg-yellow-motivation-400' />;
        case EmployeeState.PENDING_ACTIVATION:
          return <StatusCard id={employee.email} title={t(`employees_page.details.membership_state_badges.${employee.membershipState}`)} tooltipText={t(`employees_page.details.membership_state_tooltips.${employee.membershipState}`)} color='bg-grey-dark-500' />;
        case EmployeeState.APPROVAL_REQUESTED:
          return <StatusCard id={employee.email} title={t(`employees_page.details.membership_state_badges.${employee.membershipState}`)} tooltipText={t(`employees_page.details.membership_state_tooltips.${employee.membershipState}`)} color='bg-purple-community-500' />;
      }

      return (
        <span className='text-base'>{employee.membershipState}</span>
      );
    } },
  ];

  function addCheckBox() {
    columns.unshift({ name: 'ID', key: 'membershipId', type: ColumnTypes.CHECKBOX });
  }

  function removeCity() {
    const index = columns.findIndex(column => column.key === 'membership_integrationMembership_cityName');
    columns.splice(index, 1);
  }

  function removeStatus() {
    const index = columns.findIndex(column => column.key === 'membership_state');
    columns.splice(index, 1);
  }

  function addSyncColumn() {
    if (!company?.isHrIntegrationConnected) return;

    columns.push({ name: 'Sync', key: 'isSynced', className: 'max-md:hidden justify-start lg:w-[8%]', render: (row) => {
      const employee = row as Employee;
      return <div className='flex text-center justify-start mx-4'>
        {employee.isSynced ? <CheckBadgeIcon className='w-5 h-5 text-[#2EBA88]'/> : <ArrowPathIcon className='w-5 h-5 text-grey-dark-500'/>}
      </div>;
      
    } });
  }

  function buildColumns(): TableColumn[] {
    if (activeTab.generalState === EmployeeState.PENDING_APPROVAL) {
      addCheckBox();
      columns.push({ name: t('employees_page.table_column.action'), 
        key: 'actions', 
        type: ColumnTypes.BUTTON, 
        variant: Variants.SECONDARY,
        onClick: (row) => {
          const employee = row as Employee;
          if (
            employee.membershipStateActions.includes(EmployeeStateActions.APPROVE)
                  && onPrimaryButtonClick
          ) {
            onPrimaryButtonClick(employee.membershipId);
          }
          if (onSecondaryButtonClick) onSecondaryButtonClick(employee.membershipId);
        },
        className: 'md',
        render: (row) => {
          const employee = row as Employee;

          return <div className='flex justify-center gap-4 sm:mr-8 lg:mr-0 lg:ml-14'>
            <USCButton
              className='h-[32px] p-2 text-sm hidden md:flex'
              onClick={() => {
                if (
                  employee.membershipStateActions.includes(EmployeeStateActions.APPROVE)
                  && onPrimaryButtonClick
                ) {
                  onPrimaryButtonClick(employee.membershipId);
                }
              }}
            >
              {primaryButtonText ?? ''}
            </USCButton>
            <USCButton
              className='h-[32px] bg-transparent text-sm'
              variant={'link'}
              onClick={() => {
                if (onSecondaryButtonClick) onSecondaryButtonClick(employee.membershipId);
              }}
            >
              {secondaryButtonText ?? ''}
            </USCButton>
          </div>;
        }, 
      });
      removeCity();
      removeStatus();
    } else {
      
      if (activeTab.generalState === EmployeeState.ACTIVE) {
        addCheckBox();
        addSyncColumn();
      }

      if (activeTab.generalState === EmployeeState.PENDING_CANCELATION) {
        removeCity();
        removeStatus();
      }

      columns.push({
        name: t('employees_page.table_column.action'), 
        actionButtonText: secondaryButtonText ?? '', 
        key: 'actions', 
        type: ColumnTypes.BUTTON, 
        variant: Variants.SECONDARY,
        className: activeTab.generalState === EmployeeState.ACTIVE ? 'lg:w-[15%] xl:w-[12%]' : '',
        onClick: (row) => {
          const employee = row as Employee;
          if (
            employee.membershipStateActions.includes(EmployeeStateActions.APPROVE)
                  && onPrimaryButtonClick
          ) {
            onPrimaryButtonClick(employee.membershipId);
          }
          if (onSecondaryButtonClick) onSecondaryButtonClick(employee.membershipId);
        },
      });
    }

    return columns;
  }

  function onAllowBulkButtonClick() {
    pushToButtonDataLayer(onBulkButtonClickedEvent);
  }
 
  return (
    <>
      <Popup
        heading={t('employees_page.dialog.cancellation_heading')}
        isOpen={isDialogOpen}
      >
        {t('employees_page.dialog.cancellation_description')}
        <div className='popup__buttons'>
          <Button
            onClick={() => setIsDialogOpen(false)}
            text={t('employees_page.dialog.cancellation_secondary_button')}
            colors={ButtonVariants.SECONDARY}
            size={ButtonSize.SMALL}
            disabled={isLoading}
          />
          <Button 
            onClick={onBulkCancelConfirm}
            text={t('employees_page.dialog.cancellation_primary_button')}
            size={ButtonSize.SMALL}
            colors={ButtonVariants.DANGER}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </Popup>
      <Table
        key={activeTab.generalState} 
        columns={buildColumns()}
        rows={collection ?? []}
        sortable
        sortyBy={EMPLOYEES_SORT_BY_VALUES}
        sortedColumn={sortedColumn}
        onSortChange={onSortChange}
        pagination={{
          pageCount: paginationInfo ? paginationInfo.lastPage : 1,
          page: activePage,
          itemsPerPage: paginationInfo?.itemsPerPage ?? 10,
          totalItems: paginationInfo?.totalCount ?? 0,
          onPageChange: (page) => {
            onPaginationChange(page, paginationInfo?.itemsPerPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          },
          itemsPerPageOptions: PAGINATION_ITEMS_PER_LIST_VALUES,
          onChangeItemsPerPage: (itemsPerPage) => {
            onPaginationChange(1, Number(itemsPerPage)); 
          },
          paginationInfo: paginationInfo && t(
            'generic.pagination_info', {
              firstRecord: paginationInfo.itemsPerPage * activePage
              - (paginationInfo.itemsPerPage - 1),
              lastRecord: activePage === paginationInfo.lastPage
                ? paginationInfo.totalCount
                : paginationInfo.itemsPerPage * activePage,
              totalRecords: paginationInfo.totalCount,
            },
          ),
          currentPage: activePage,
          
          onNextPage() {
            onPaginationChange(activePage + 1);
          },
          onPreviousPage() {
            onPaginationChange(activePage - 1);
          },
        }}
        bulkOptions={{
          allowBulkEdit: statesForBulkProcess.includes(activeTab.generalState as EmployeeState),
          editButtonTextSingle: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? t('employees_page.list.employee_approve_button') : t('employees_page.dialog.cancellation_primary_button_single'),
          editButtonText: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? t('employees_page.list.employee_approve_button') : t('employees_page.dialog.cancellation_primary_button'),
          editButtonOnClick: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? onBulkApprove : onBulkCancel,
          allowBulkButtonText: 'Bulk Approve',
          onAllowBulkButtonClick: onAllowBulkButtonClick,
          alwaysVisibleCheckbox: statesForBulkProcess.includes(activeTab.generalState as EmployeeState),
          selectedButtonColor: activeTab.generalState === EmployeeState.PENDING_APPROVAL ? ButtonVariants.SECONDARY : ButtonVariants.DANGERSECONDARY,

        }}
        isLoading={isLoading}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
    </>
  );
};

export default EmployeesList;
