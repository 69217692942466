import { tv } from 'tailwind-variants';

export const radio = tv({
  base: "[&:checked]:bg-[url('data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%273%27/%3e%3c/svg%3e')]",
});


export const employeeVariant = tv({
  base: 'relative flex justify-between items-center py-4 px-[8px] mx-[-8px]',
  variants: {
    isSelected: {
      true: 'bg-grey-natural-200 rounded-md',
    },
  },
});
