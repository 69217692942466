import React from 'react';
import { Alert } from '@urbansportsclub/components/Alert';
import { AlertTitle } from '@urbansportsclub/components/Alert/Title';
import { AlertContent } from '@urbansportsclub/components/Alert/Content';
import { Text } from '@urbansportsclub/components/Text';


const InvoiceAlert = () => {
  return (
    <Alert severity='info' showCloseButton={false} className='p-4 mb-6'>
      <AlertTitle className='text-base'>Please transfer the amount by the mentioned date on invoice.</AlertTitle>
      <AlertContent>
        <Text as='span' intent='md'>
          If you think there’s a mistake with your billing, please write to billing@urbansportsclub.com
        </Text>
      </AlertContent>
    </Alert>
  );
};

export default InvoiceAlert;
