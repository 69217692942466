import { useRef, useState } from 'react';
import { IInputProps } from './TagField.types';
import * as styles from './TagField.variants';
import { Tooltip } from '@urbansportsclub/components/Tooltip';
import { TooltipContent } from '@urbansportsclub/components/Tooltip/Content';
import { TooltipTrigger } from '@urbansportsclub/components/Tooltip/Trigger';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function TagField({ icon, className, placeholder, id, type, name, onChange, onRemove, badges, value, onPaste, hasTooltip = true }: IInputProps) {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  
  function onDivClick() {
    inputRef.current?.focus();
  }

  const renderField = () => {
    return (
      <div className={styles.divInput({ focus })} onClick={onDivClick}>
        <div className={styles.inputIcon()}>
            {icon}
        </div>
        <div className='flex flex-grow overflow-auto'>
          {badges && badges.length > 0 && 
            badges.map((badge) => (
              <span className='inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 mr-1 mb-1' key={badge}>
                  {badge}
                  <button type='button'
                      className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20'
                      onClick={() => { onRemove && onRemove(badge); }}
                  >
                      <span className='sr-only'>Remove</span>
                      <svg viewBox='0 0 14 14' className='h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75'>
                          <path d='M4 4l6 6m0-6l-6 6' />
                      </svg>
                      <span className='absolute -inset-1' />
                  </button>
              </span>
            ))
          }
          <input
              type={type}
              name={name}
              id={id}
              className={styles.input({ className })}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              value={value}
              onPaste={onPaste}
              ref={inputRef}
          />
        </div>
      </div>
    );
  };

  if (!hasTooltip) return renderField();

  return (
    <Tooltip>
      <TooltipTrigger className='w-[100%]'>
        {renderField()}
      </TooltipTrigger>
      <TooltipContent placement='bottom' className='text-base text-wrap p-5'>
        {t('tooltip_search')}
      </TooltipContent>
    </Tooltip>
  );
}
