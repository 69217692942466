import React, { useContext } from 'react';
import { ReactComponent as HriSearching } from 'assets/images/hri-searching.svg';
import { ReactComponent as HriFound } from 'assets/images/hri-found.svg';
import { Text } from '@urbansportsclub/components/Text';
import { capitalizeFirstLetter, isEmailValid } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';

interface INoHriResults {
  searchInputValue?: string,
}

const NoHriResults = ({ searchInputValue } : INoHriResults) => {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);

  const isSearch = searchInputValue?.length !== 0 && isEmailValid(searchInputValue as string);

  if (!company) return <></>;

  return (
    <div>
      <Text intent='sm' as='p' className='mt-2'>{isSearch ? t('hri_sync.no_results.with_email_msg') : t('hri_sync.no_results.no_email_msg').replace('HRI_SYSTEM', capitalizeFirstLetter(company.hrIntegrationName))}</Text>
      { isSearch
        ? <HriSearching className='mx-auto w-[50%] h-auto mt-8' /> 
        : <HriFound className='mx-auto w-[50%] h-auto mt-8' />
      }
    </div>
  );
};

export default NoHriResults;
