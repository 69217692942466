import { tv } from 'tailwind-variants';

export const cardVariant = tv({
  base: 'flex flex-col justify-between cursor-pointer bg-white-natural-500 overflow-hidden',
  variants: {
    isSelected: {
      true: 'border-[2px] border-blue-respect-500',
      false: 'hidden md:flex',
    },
  },
});
