import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Text } from '@urbansportsclub/components/Text';
import { Button } from '@urbansportsclub/components/Button';
import { EmployeesData } from 'utils/graphql/queries/employee';
import { Employee } from 'models/employee';
import { Radio } from '@urbansportsclub/components/Radio';
import * as styles from './EmployeeListDialog.variants';
import { Badge } from '@urbansportsclub/components/Badge';
import { useTranslation } from 'react-i18next';

interface IEmployeesListDialog {
  data: EmployeesData | undefined,
  selectedEmployeeId: string | null,
  setSelectedEmployeeId: (id: string) => void,
  isOpen: boolean,
  setOpen: (isOpen: boolean) => void,
}

const EmployeesListDialog = ({ data, selectedEmployeeId, setSelectedEmployeeId, isOpen, setOpen } : IEmployeesListDialog) => {
  const [selectedId, setSelectedId] = useState<string | null>(selectedEmployeeId);
  const { t } = useTranslation();

  const { collection } = data?.employees || {};

  useEffect(() => {
    if (isOpen) {
      setSelectedId(selectedEmployeeId);
    }
  }, [isOpen]);

  const onSubmit = () => {
    setSelectedEmployeeId(selectedId as string);
    setOpen(false);
  };

  const renderEmployee = (employee: Employee) => {
    return (
      <div className={styles.employeeVariant({ isSelected: selectedId === employee.id })} onClick={() => setSelectedId(employee.id)}>
        <Text as='span' intent='md'>{employee.firstName} {employee.lastName}</Text>
        <div className='flex items-center'>
          { employee.id == selectedEmployeeId && <Badge variant='primary' className='mr-2'>{t('invoice.current')}</Badge> }
          <Radio checked={selectedId === employee.id} className={styles.radio()} />
        </div>
      </div>
    );
  };

  if (!isOpen) return <></>;

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-[9999]' onClick={() => setOpen(false)}>
      <div className='w-10/12 h-full bg-white shadow-lg flex flex-col rounded-tl-lg' onClick={(e) => e.stopPropagation()}>
        <div className='py-4 px-6 border-b border-grey-natural-500 flex justify-between items-start'>
          <div>
            <Text as='h2' intent='md' weight='bold' className='flex items-center gap-x-2'>
              {t('hri_sync.unsynced.mob')}
              <Badge variant='secondary' className='pt-[1px] bg-grey-natural-200 text-grey-dark-700 border-grey-natural-500'>{collection?.length}</Badge>
            </Text>
            <Text as='p' intent='md' className='!mt-2'>{t('hri_sync.mob_drawer.subtitle')}</Text>
          </div>
          <div>
            <XMarkIcon className='h-5 w-5 text-gray-400 cursor-pointer' onClick={() => setOpen(false)} />
          </div>
        </div>


        <div className='flex-1 overflow-y-auto py-4 pl-6 pr-4 mr-2'>
          <div className=''>
            { collection?.length &&  collection.map((employee: Employee, index: number) => renderEmployee(employee))}
          </div>
        </div>

        <div className='p-4 border-t border-grey-natural-500'>
          <Button fullWidth onClick={onSubmit}>Save Selection</Button>
        </div>
      </div>
    </div>
  );
};

export default EmployeesListDialog;
