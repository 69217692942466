export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}


export function isDevEnv() {
  return ['localhost', 'portal-staging', 'b2b-portal'].includes(
    window.location.hostname.split('.')[0],
  );
}

export function isEmailValid(email: string) {
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}


interface IObject {
  [key: string]: unknown;
}

interface SortedObjectKeys {
  key: string;
  items: unknown;
}

export function sortObjectWithKeys(items: IObject) {
  //sort the object items by key in descending order

  const sortedItems: SortedObjectKeys[] = Object.keys(items)
    .sort((a, b) => {
      if (a > b) return -1;
      return 1;
    })
    .map((key) => ({ key, items: items[key] }));

  return sortedItems;
}