import TagManager from 'react-gtm-module';
import chosenCompanyVar, { ChosenCompany } from './graphql/localState/chosenCompany';

declare global {
  interface Window {
    dataLayer: Array<DataLayerEvent>;
  }
}

export interface DataLayerEvent extends DataLayerEventMain, DataLayerPayload, CommonDataLayerEvent {
  event: string;
}

export interface CommonDataLayerEvent {
  entity: string;
  user: {
    company: string;
    id: string;
  };
}

export interface DataLayerEventMain {
  event_group: string;
  event_name: string;
  event?: string;
}

export interface DataLayerPayload {
  error_message?: string;
  page: {
    type: string;
    language: string | undefined;
    environment: string | undefined;
  };
}

export type DataLayerEvents = Record<string, DataLayerEventMain>;

export const dataLayerPlatform: DataLayerPayload = {
  page: {
    type: 'b2b_portal',
    language: localStorage.getItem('lng') ?? 'en',
    environment: process.env.REACT_APP_ENVIRONMENT ?? 'development',
  },
};



function getChoosenCompany() {
  const chosenCompany: ChosenCompany | void = chosenCompanyVar.init() ?? chosenCompanyVar.restore();
  //   eslint-disable-next-line
  const integrationName: string = chosenCompany?.integrationName?.split('-')[0] ?? '';

  return {
    name: chosenCompany?.name ?? '',
    integrationName,
    id: chosenCompany?.id ?? '',
  };
}

export interface ButtonLayerEvent {
  item: string;
  origin: string;
  details?: string;
}

export interface ButtonPayloadLayerEvent extends CommonDataLayerEvent, ButtonLayerEvent {
  event: string;
}


export const defaultButtonPayloadLayerEvent = (): ButtonPayloadLayerEvent => ({
  event: 'button_clicked',
  entity: getChoosenCompany().integrationName,
  user: {
    company: getChoosenCompany().name,
    id: getChoosenCompany().id,
  },
  item: '',
  origin: '',
});

export const pushToButtonDataLayer = (buttonLayerEvent: ButtonLayerEvent): void => {
  const dataLayer: object = {
    ...defaultButtonPayloadLayerEvent(),
    ...buttonLayerEvent,
  };

  TagManager.dataLayer({
    dataLayer,
  });
};

export const pushToActionEvent = (event: string, error_message?: string): void => {
  const dataLayer: object = {
    event,
    entity: getChoosenCompany().integrationName,
    user: {
      company: getChoosenCompany().name,
      id: getChoosenCompany().id,
    },
    error_message,
  };

  TagManager.dataLayer({
    dataLayer,
  });
};

export const pushDataLayerEvent = (
  dataLayerEvent: DataLayerEventMain,
  dataLayerPayload?: DataLayerPayload,
): void => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: dataLayerEvent.event_name,
      entity: getChoosenCompany().integrationName,
      ...dataLayerEvent,
      ...dataLayerPayload ?? dataLayerPlatform,
      user: {
        company: getChoosenCompany().name,
        id: getChoosenCompany().id,
      },
    });
  }
};

export interface PayloadLayerEvent extends ButtonLayerEvent {
  event: string;
}

export const pushToDataLayer = (payloadEvent: PayloadLayerEvent): void => {
  const dataLayer: object = {
    ...defaultButtonPayloadLayerEvent(),
    ...payloadEvent,
  };

  TagManager.dataLayer({
    dataLayer,
  });
};

export const EVENT_GROUP_NAME = 'custom';