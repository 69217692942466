import React, { useContext, useEffect, useState } from 'react';
import SidebarLayout from 'components/templates/SidebarLayout/SidebarLayout';
import InvoiceAlert from './components/InvoiceAlert';
import Invoice from './components/Invoice';
import { CompanyContext } from 'contexts/CompanyContext';
import { useGetInvoices } from 'services/invoiceService';
import Datepicker from 'react-tailwindcss-datepicker';
import { Text } from '@urbansportsclub/components/Text';
import NoInvoiceFound from './components/NoInvoiceFound';
import { INVOICES_MIN_DATE, INVOICE_DATE_FORMAT } from 'utils/constants';
import { sortObjectWithKeys } from 'utils/helpers';

type Invoice = {
  id: string;
  number: string;
  documentDate: string;
  amount: number;
  currency: string;
};

const Invoices = () => {
  const MIN_DATE = new Date(INVOICES_MIN_DATE);
  const { company } = useContext(CompanyContext);
  const { data, getInvoces, loading } = useGetInvoices();

  const [value, setValue] = useState<any>({
    startDate: INVOICES_MIN_DATE,
    endDate: null,
  });


  useEffect(() => {
    if (company?.id) {
      getInvoces(company.id);
    }
  }, [company?.id]);

  const groupedInvoices = data?.reduce((acc: { [key: string]: Invoice[] }, invoice: Invoice) => {
    const year = new Date(invoice.documentDate).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(invoice);
    return acc;
  }, {} as { [key: string]: Invoice[] });

  const renderInvoices = () => {
    return (
      <>
        <InvoiceAlert />
        {groupedInvoices &&
          sortObjectWithKeys(groupedInvoices).map((invoiceYear) => (
            <div key={invoiceYear.key}>
              <Text as='span' intent='lg' weight='bold' className='inline-block mb-3'>{invoiceYear.key}</Text>
              {(invoiceYear.items as Invoice[]).map((invoice: any, index: number) => (
                <Invoice
                  key={invoice.id}
                  id={invoice.id}
                  number={invoice.number}
                  documentDate={invoice.documentDate}
                  amount={invoice.amount}
                  currency={invoice.currency}
                  isNew={index === 0}
                />
              ))}
            </div>
          ))}
      </>
    );
  };

  const renderNoInvoicesFound = () => {
    return (
      <NoInvoiceFound isFiltered={value.endDate} />
    );
  };

  return (
    <SidebarLayout heading='Invoices' showDownloadList={false} className='relative'>
      <div className='w-full md:w-[250px] md:absolute md:top-[-64px] md:right-[0] mb-6 md:mb-0 md:mr-6 lg:mr-8 [&_input]:pr-10 [&>div>div]:right-0 [&>div>div]:w-[100%] [&>div>div]:md:w-[unset]'>
        <Datepicker
          useRange={false}
          readOnly={true}
          value={value}
          onChange={newValue => {
            setValue(newValue);
            if (newValue?.startDate) {
              const startDate = newValue?.startDate?.toISOString().substring(0, 10);
              const endDate = newValue?.endDate?.toISOString().substring(0, 10);
              getInvoces(company?.id as string, startDate, endDate);
            } else {
              getInvoces(company?.id as string);
            }
          }}
          minDate={MIN_DATE}
          popoverDirection='down'
          displayFormat={INVOICE_DATE_FORMAT}
        />
      </div>
      {!loading && (!data?.length ? renderNoInvoicesFound() : renderInvoices())}
    </SidebarLayout>
  );
};

export default Invoices;
