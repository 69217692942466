import React, { useState } from 'react';
import { Employee } from 'models/employee';
import { EmployeesData } from 'utils/graphql/queries/employee';
import EmployeeCard from './EmployeeCard';

interface IEmployeesList {
  data: EmployeesData | undefined,
  selectedEmployeeId: string | null,
  setSelectedEmployeeId: (id: string) => void,
}

const EmployeesList = ({ data, selectedEmployeeId, setSelectedEmployeeId } : IEmployeesList) => {
  const { collection } = data?.employees || {};

  if (!collection?.length) return <></>;

  return (
    <div className='scrollbar2 md:max-h-[calc(100vh-220px)] md:overflow-y-auto md:pr-2'>
      <div className='flex flex-col gap-y-4'>
        { collection.map((employee, index) => 
            <EmployeeCard
              employee={employee}
              key={employee.id}
              selectedId={selectedEmployeeId}
              setSelectedId={setSelectedEmployeeId}
            />,
        )}
      </div>
    </div>
  );
};

export default EmployeesList;
