import React from 'react';
import { ReactComponent as NoInvoiceFoundImg } from 'assets/images/no-invoices-found.svg';
import { Text } from '@urbansportsclub/components/Text';
import { Link } from 'react-router-dom';

interface INoInvoiceFound {
  isFiltered: string | null
}

const NoInvoiceFound = ({ isFiltered } : INoInvoiceFound) => {
  return (
    <div className='relative md:top-[80px]'>
      <NoInvoiceFoundImg className='mx-auto w-[100%]' />
      { isFiltered ? (
        <div className='text-center mt-10 text-black-midnight-500'>
          <Text as='p' intent='md' weight='bold' className='mb-4'>The selected date range doesn't contain any invoices. Please try again.</Text>
        </div>
      ) : (
        <div className='text-center mt-10 text-black-midnight-500'>
          <Text as='p' intent='md' weight='bold' className='mb-4'>It seems like you do not have access to view this feature.</Text>
          <Text as='p' intent='md'>If this is a mistake, please contact your admins (find them from <Link className='text-black-midnight-500 cursor-pointer underline' to='/account'>Accounts page -{'>'} Manage Team</Link>)</Text>
          <Text as='p' intent='md'>or reach out to us through <Link className='text-black-midnight-500 cursor-pointer underline' to='/support'>Contact Us</Link> form to request for help.</Text>     
        </div>
      )}
    </div>
  );
};

export default NoInvoiceFound;
