import { pushToButtonDataLayer, pushToDataLayer } from 'utils/dataLayer';

type ButtonClickEventName = 
    'Employee Selected' | 
    'Back to Employee List' | 
    'Link Employee' | 
    'Skip Employee' | 
    'Sync Data' | 
    'Stay To Complete Setup' | 
    'Leave HRIs Setup' | 
    'Cancel Match';

export const UpdateDataNow = () => {
  pushToButtonDataLayer({
    item: 'Update Data Now',
    origin: 'Home',
    details: 'Hr Integration',
  });
};

export const HrManualButtonClicked = (item: ButtonClickEventName) => {
  pushToButtonDataLayer({
    item,
    origin: 'Hr Manual Data Tracking',
    details: 'Hr Integration',
  });
};


export const SearchEvent = () => {
  pushToDataLayer({
    event: 'search',
    item: 'Search Employee',
    details: 'Manual Data Tracking',
    origin: 'Hr Manual Data Tracking',
  });
};

export const EmployeeSelectedFromSearchList = () => {
  pushToDataLayer({
    event: 'radio_button_clicked',
    item: 'Employee Selected',
    details: 'Manual Data Tracking',
    origin: 'Hr Manual Data Tracking',
  });
};
