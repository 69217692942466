export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  personnelNumber: string;
  membershipPlanTypeName: string;
  membershipMinimumPeriodMonths: string;
  membershipStartDate: string;
  currentPeriodStartDate: string;
  currentPeriodEndDate: string;
  membershipCancellationDate: string;
  membershipSignUpDate: string;
  membershipCardNumber: string;
  membershipCityName: string;
  membershipState: EmployeeState;
  membershipIntegrationState: string;
  membershipCancellationOptions: EmployeeMembershipCancelationOptions;
  membershipStateActions: Array<EmployeeStateActions>;
  membershipId: string;
  isSynced: boolean;
}

export enum EmployeeState {
  ACTIVE = 'active',
  PENDING_ACTIVATION = 'pending_activation',
  APPROVAL_REQUESTED = 'approval_requested',
  PAUSED = 'paused',
  PENDING_CANCELATION = 'pending_cancellation',
  PENDING_APPROVAL = 'pending_approval',
  CANCELLATION_REQUESTED = 'cancellation_requested',
}

export enum EmployeeStateActions {
  APPROVE = 'approve',
  REJECT = 'reject',
  CANCEL = 'cancel',
}

export interface EmployeeMembershipCancelationOptions {
  employee_leaving: Array<string>;
  other: Array<string>;
}

export interface EmployeesTab {
  generalState: EmployeeState;
  status: Array<EmployeeState>;
}

export interface EmployeesTabsState {
  tabs: Array<EmployeesTab>,
  activeIndex: number;
}

export interface Personnel {
  hri_id: string,
  email: string,
  employee_number: string,
  first_name: string,
  last_name: string,
  start_date: string | null,
}
