import Reactivation, { Confirmation } from 'components/pages/Reactivation';
import { RouterProps } from 'routes';

const DevRoutes: RouterProps[] = [
  {
    path: '/reactivation',
    component: Reactivation,
    pageName: 'reactivation',
  },
  {
    path: '/reactivation/confirmation',
    component: Confirmation,
    pageName: 'reactivation_confirmation',
  },
];

export default DevRoutes;