import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import Form, { FormMethod } from 'components/atoms/Form';
import FormField from 'components/molecules/FormField';
import Separator from 'components/atoms/Separator';
import { login, isAuthenticated } from 'services/authService';
import { EMAIL_REGEX } from 'utils/constants';
import { pushDataLayerEvent, DataLayerEvents, EVENT_GROUP_NAME, dataLayerPlatform } from 'utils/dataLayer';
import Button from 'components/atoms/Tailwind/Button';
import { UserContext } from 'contexts/UserContext';

const DATA_LAYER_EVENTS: DataLayerEvents = {
  loginView: { event_group: EVENT_GROUP_NAME, event_name: 'login_viewed' },
  loginAttempt: { event_group: EVENT_GROUP_NAME, event_name: 'login_attempted' },
  loginSuccess: { event_group: EVENT_GROUP_NAME, event_name: 'login_succeeded' },
  loginFailure: { event_group: EVENT_GROUP_NAME, event_name: 'login_failed' },
};

interface FormInputs {
  email: string;
  password: string;
}

const LoginPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    pushDataLayerEvent(DATA_LAYER_EVENTS.loginView);
    localStorage.removeItem('authToken');
  }, []);

  

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<FormInputs>({ mode: 'all' });

  const keyPressHandler: React.KeyboardEventHandler<HTMLInputElement> = (
    { key, shiftKey },
  ): void => {
    if (key === 'Enter' && !shiftKey) {
      void handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data: FormInputs) => {
    setIsLoading(true);
    setFormError('');
    pushDataLayerEvent(DATA_LAYER_EVENTS.loginAttempt);

    const cloneData = Object.assign({}, data);
    cloneData.email = cloneData.email.toLowerCase();
    const response = await login(cloneData);

    setIsLoading(false);

    if (response && response.code === 401) {
      setFormError(t('login.invalid_credentials_error'));
      pushDataLayerEvent(DATA_LAYER_EVENTS.loginFailure, { error_message: t('login.invalid_credentials_error'), page: { ...dataLayerPlatform.page } });
    } else if (response && response.code !== 200) {
      setFormError(response.message);
      pushDataLayerEvent(DATA_LAYER_EVENTS.loginFailure, { error_message: response.message, page: { ...dataLayerPlatform.page } });
    }

    if (isAuthenticated()) {
      pushDataLayerEvent(DATA_LAYER_EVENTS.loginSuccess);
      localStorage.setItem('email', cloneData.email);
      setUser();
      navigate('/choose-company');
    }
  };

  return (
    <SingleColumnLayout className="login-page">
      <SimpleSection title={t('login.title')}>
        <Form method={FormMethod.POST} onSubmit={handleSubmit(onSubmit)} error={formError}>
          <FormField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email', { required: true, pattern: EMAIL_REGEX })}
            type='email'
            placeholder={t('forms.email.placeholder')}
            error={errors.email}
            onKeyPress={keyPressHandler}
          />
          <FormField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('password', { required: true })}
            type="password"
            placeholder={t('forms.password.placeholder')}
            error={errors.password}
            onKeyPress={keyPressHandler}
          />
          <Separator offset={0} />
          <Button text={t('login.submit_button')} disabled={!isValid || isLoading} fullWidth type='submit' isLoading={isLoading}/>
        </Form>
      </SimpleSection>
      <strong className="single-column-layout__link">
        <Link to="/forgot-password">{t('login.forgot_password_link')}</Link>
      </strong>
    </SingleColumnLayout>
  );
};

export default LoginPage;
