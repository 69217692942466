import React, { useState } from 'react';
import { Employee } from 'models/employee';
import { Card } from '@urbansportsclub/components/Card';
import { Badge } from '@urbansportsclub/components/Badge';
import { Text } from '@urbansportsclub/components/Text';
import { formatDate } from 'utils/date';
import * as styles from './EmployeeCard.variants';
import StatusCard from 'components/molecules/EmployeesList/components/StatusCard';
import { useTranslation } from 'react-i18next';

interface IEmployeeCard {
  employee: Employee,
  selectedId: string | null,
  setSelectedId: (id: string) => void,
}

const EmployeeCard = ({ employee, selectedId, setSelectedId } : IEmployeeCard) => {
  const { t } = useTranslation();

  const isSelected = selectedId === employee.id;

  return (
    <Card variant='option' className={ styles.cardVariant({ isSelected }) } onClick={() => setSelectedId(employee.id)}>
      <div className='flex flex-col mb-2'>
        <div className='flex items-center justify-between'>
          <Text as='span' intent='md' weight='bold' className='text-black-midnight-light-500'>{`${employee.firstName} ${employee.lastName}`}</Text>
          {employee.membershipState === 'paused' && (
            <div className='[&>div>span]:m-[0px]'>
              <StatusCard id={employee.email} title={t('employees_page.details.membership_state_badges.paused')} color='bg-yellow-motivation-400' />
            </div>
          )}
        </div>
        <Text as='span' intent='md' className='text-black-midnight-light-500'>{employee.email}</Text>
      </div>
      <div className='flex justify-between pt-2 border-t border-t-[1px] border-grey-natural-500'>
        <Text as='span' intent='md' className='text-grey-dark-500'>Started on {formatDate(new Date(employee.membershipStartDate))}</Text>
        <Badge variant='primary'>{employee.membershipPlanTypeName}</Badge>
      </div>
    </Card>
  );
};

export default EmployeeCard;
