import React, { useContext } from 'react';
import { Text } from '@urbansportsclub/components/Text';
import PersonnelCard from './PersonnelCard';
import { Personnel } from 'models/employee';
import TagField from 'components/molecules/TagsField/TagField';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CompanyContext } from 'contexts/CompanyContext';
import { Loader } from 'components/atoms/Tailwind/Loader/Loader';
import NoHriResults from './NoHriResults';
import { capitalizeFirstLetter, isEmailValid } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

interface IHrData {
  isLoading: boolean,
  data?: Array<Personnel> | [],
  selectedPersonnelId: string | null,
  setSelectedPersonnelId: (id: string) => void,
  searchInputValue: string,
  onSearchHandler: React.ChangeEventHandler<HTMLInputElement>,
  onSearchClear: () => void,
  errorMsg?: string,
  debouncedSearchInput?: string,
}

const HrData = ({ isLoading, data, selectedPersonnelId, setSelectedPersonnelId, searchInputValue, onSearchHandler, onSearchClear, errorMsg, debouncedSearchInput } : IHrData) => {
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();

  const renderTitle = () => {
    if (!company) return <></>;

    const title = capitalizeFirstLetter(company.hrIntegrationName);

    return  <Text as='h2' intent='md' weight='bold' className='md:hidden capitilize'>{title} {t('hri_sync.hri_title')}</Text>;
  };

  return (
    <div className='mt-4 bg-white-natural-500 p-6 border border-1 border-grey-dark-200 rounded-xl md:min-h-[calc(100vh-410px)]'>
      { renderTitle() }
      <div>
        <Text as='span' intent='md'>{t('hri_sync.email_search.title')}</Text>
        <div className='mt-2 [&_input]:w-[95%] max-md:[&_input]:pl-[0px] max-md:[&_input]:pr-7 relative'>
          <TagField
            name="search"
            type="text"
            placeholder={t('hri_sync.email_search.placeholder')}
            icon={<MagnifyingGlassIcon className='h-5 w-5 text-gray-400' />}
            onChange={onSearchHandler}
            value={searchInputValue}
            hasTooltip={false}
          />
          { searchInputValue && <XMarkIcon className='h-5 w-5 text-gray-400 cursor-pointer absolute right-3 bottom-4 z-10' onClick={onSearchClear} /> }
        </div>
        { errorMsg && <Text as='h2' intent='md' className='text-orange-passion-700 my-2'>{errorMsg}</Text> }
      </div>
      { data?.length !== 0 && !errorMsg && (
        <div className="flex items-center justify-center space-x-2 my-4">  
          <hr className="flex-grow border-t border-gray-300" />  
          <Text as='span' intent='sm' className='text-grey-dark-500'>{t('hri_sync.hr_data.suggestion')}</Text>
          <hr className="flex-grow border-t border-gray-300" />  
        </div>
      )}
      { isLoading ? (
        <div className='[&>svg]:w-10 [&>svg]:h-10 flex justify-center'><Loader colors='blue' /></div>
      ) : (
        <>
          {
            (data?.length == 0) ? (
              <NoHriResults searchInputValue={searchInputValue} />
            ) : (
              <div className='scrollbar2 md:max-h-[calc(100vh-410px)] md:overflow-y-auto pr-2 mr-[-12px]'>
                <div className='flex flex-col gap-y-4'>
                  { data && data.map((personnel: Personnel) => 
                    <PersonnelCard
                      data={personnel}
                      key={personnel.hri_id}
                      selectedId={selectedPersonnelId}
                      setSelectedId={setSelectedPersonnelId}
                    />,
                  )}
                </div>
              </div>
            )
          }
        </>
      ) }
    </div>
  );
};

export default HrData;
