import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDownloadOrganisationReports } from 'services/reportService';
 
const DownloadReport = () => {
  const { year, month } = useParams();
  const navigate = useNavigate();
  const { downloadOrganisationReport } = useDownloadOrganisationReports();
  const hasDownloaded = useRef(false);

  useEffect(() => {
    if (!hasDownloaded.current) {
      downloadOrganisationReport(year as string, month as string);
      hasDownloaded.current = true;
      navigate('/report');  
    }
  }, []);

  return <></>;
};

export default DownloadReport;
