import { tv } from 'tailwind-variants';

export const cardVariant = tv({
  base: 'relative flex items-center gap-x-4 cursor-pointer p-4',
  variants: {
    isSelected: {
      true: 'border-2 border-blue-respect-500',
    },
  },
});

export const radio = tv({
  base: "absolute top-4 right-4 [&:checked]:bg-[url('data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%273%27/%3e%3c/svg%3e')] mb-3",
});
